<template>
  <div class="sc-wrapper">
    <Configurator ref="configurator" :orgId="orgId"/>
    <custom-full-calendar
        v-if="renderCalendar"
        v-model:date="date"
        :events="events"
        :holidays="getHolidays"
        :type="calendarType"
        :view="calendarView"
        :viewTypes="calendarViewTypes"
    />
  </div>
</template>

<script>

import * as moment from 'moment-timezone';
import {WeekDayEnum} from '@/enums/week-day.enum.js';
import Configurator from './configurator';
import CustomFullCalendar from '@/components/CustomFullCalendar';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {mapActions, mapGetters, mapMutations} from "vuex";
// import _ from 'lodash';

export default {
  name: 'calendar-configurator',
  components: {
    'custom-full-calendar': CustomFullCalendar,
    Configurator,
  },
  props: {
    orgId: {
      type: [String, Number],
      default: "1",
      required: true,
    },
  },
  data: () => ({
    date: moment(),
    events: [],
    firstRun: true,
    calendarView: ViewEnum.TIMELINE,
    calendarType: ViewTypeEnum.MONTH,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => i !== ViewTypeEnum.YEAR).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    invalidEvents: [],
    renderCalendar: true,
    moment,
  }),
  created() {
    this.moment = moment;
  },
  async mounted() {
    await this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    await this.callAlarmPlans(this.orgId);
    await this.callEvents();
  },
  methods: {
    ...mapActions({
      'callEvents': 'rotationalCalendar/callEvents',
      'callAlarmPlans': 'rotationalCalendar/callAlarmPlans',
      'callCreateCalendar': 'rotationalCalendar/callCreateCalendar',
      'callUpdateCalendar': 'rotationalCalendar/callUpdateCalendar',
      'callRemoveCalendar': 'rotationalCalendar/callRemoveCalendar',
      'callGetHolidays': 'holidays/callGetHolidays',
    }),
    ...mapMutations({
      'setCalendar': 'rotationalCalendar/setCalendar',
      'setIsEditable': 'rotationalCalendar/setIsEditable',
      'createWeek': 'rotationalCalendar/createWeek',
      'removeWeek': 'rotationalCalendar/removeWeek',
      'createEvent': 'rotationalCalendar/createEvent',
      'removeEvent': 'rotationalCalendar/removeEvent',
      'cloneEvent': 'rotationalCalendar/cloneEvent',
    }),
    generateEvents: function () {
      if (!this.getEvents) return [];
      let events = [];
      const eventGroups = JSON.parse(JSON.stringify(this.getEvents));
      const countWeeks = eventGroups.length;

      let currentWeekInMonth = Math.ceil(
          moment(this.dateStartWeekMonth).diff(this.validFrom, 'weeks') % countWeeks
      );
      eventGroups.map((week, weekIndex) => {
        week.alarmPointRotationEvents.map(eventRule => {
          let times = [];
          const calendar = this.getCalendars.find(i => i.id === eventRule.rotationalCalendarId) ?? this.getCalendar;
          const alarmPlan = this.getAlarmPlans.find(i => i.id === eventRule.alarmplanId) ?? null;
          for (
              let d = moment(this.dateStartWeekMonth);
              d.diff(moment(this.dateEndWeekMonth), 'days') <= 0;
              d.add(1, 'days')
          ) {
            const holiday = this.getHolidays ? this.getHolidays.find(i =>
                moment(i.start).isSameOrBefore(moment(d).endOf('days'))
                && moment(i.end).isSameOrAfter(moment(d).startOf('days'))
            ) : null;
            if (currentWeekInMonth === weekIndex && d.isSameOrAfter(calendar?.validFrom)) {
              // check day of week
              if (/*d.week() === moment(d).startOf('months').add(weekIndex, 'weeks').week()
                  && d.isSameOrAfter(calendar?.validFrom)
                  &&*/ eventRule.dateRule.markedDays.indexOf(d.format('dddd').toLocaleUpperCase()) !== -1
              ) {
                if (!holiday) {
                  times.push({
                    start: d.set({
                      hour: eventRule.dateRule.start.split(':')[0],
                      minutes: eventRule.dateRule.start.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    end: d.set({
                      hour: eventRule.dateRule.end.split(':')[0],
                      minutes: eventRule.dateRule.end.split(':')[1]
                    }).format('YYYY-MM-DD HH:mm:ss'),
                    isHoliday: eventRule.dateRule.holiday,
                  });
                }
              }
              // check holidays
              if (eventRule.dateRule.holiday && holiday) {
                times.push({
                  start: d.set({
                    hour: eventRule.dateRule.start.split(':')[0],
                    minutes: eventRule.dateRule.start.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  end: d.set({
                    hour: eventRule.dateRule.end.split(':')[0],
                    minutes: eventRule.dateRule.end.split(':')[1]
                  }).format('YYYY-MM-DD HH:mm:ss'),
                  isHoliday: eventRule.dateRule.holiday,
                  holiday,
                });
              }


            }

            if (moment(d).diff(moment(this.validFrom).subtract(1, 'days'), 'weeks') < 0) {
              currentWeekInMonth = 0;
            } else {
              currentWeekInMonth = Math.ceil(moment(d).diff(moment(this.validFrom).subtract(1, 'days'), 'weeks') % countWeeks);
            }
            if (times.length) {
              let event = events.find(i => i.alarmplanId === eventRule.alarmplanId) ?? null;
              if (event) {
                event.times = event.times.concat(times);
              } else {
                event = {
                  title: (alarmPlan?.name || this._t('postpone_action')),
                  alarmplanId: eventRule.alarmplanId,
                  times,
                };
                events.push(event);
              }
              times = [];
            }
          }
        })
      });

      this.events = events;
      if (this.firstRun) {
        // This is a fix, do not delete this block
        this.firstRun = false;
        this.date = moment();
      }
      this.$refs.configurator.validateWeeks();
      return events;
    },
    forceRerender() {
      this.renderCalendar = false;
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
    createUpdateNewCalendar() {
      this.$refs.configurator.createUpdateNewCalendar();
    },
    validateWeeks() {
      this.$refs.configurator.validateWeeks();
    }
  },
  computed: {
    ...mapGetters({
      'getCalendars': 'rotationalCalendar/getCalendars',
      'getCalendar': 'rotationalCalendar/getCalendar',
      'getEvents': 'rotationalCalendar/getEvents',
      'getAlarmPlans': 'rotationalCalendar/getAlarmPlans',
      'getIsEditable': 'rotationalCalendar/getIsEditable',
      'getHolidays': 'holidays/getHolidays',
    }),
    dateStartWeekMonth() {
      return this.moment(this.date).startOf('months').startOf('weeks').format('YYYY-MM-DD');
    },
    dateEndWeekMonth() {
      return this.moment(this.date).endOf('months').endOf('weeks').format('YYYY-MM-DD');
    },
    validFrom: {
      get() {
        let calendar = this.getCalendar;
        if (calendar) {
          return moment(calendar.validFrom, 'YYYY-MM-DD HH:mm:ss').toDate();
        }
        return null;
      },
      set(val) {
        let calendar = this.getCalendar;
        calendar.validFrom = moment(val).format('YYYY-MM-DD HH:mm:ss');
        this.setCalendar(calendar);
      }
    },
    weekDay: () => WeekDayEnum,
  },
  watch: {
    'calendarOptions.dow': function () {
      this.forceRerender();
    },
    getEvents: function () {
      this.generateEvents();
    },
    date: function () {
      this.generateEvents();
    },
  },
}
</script>

<style scoped>
:deep(.sc-calendar__header) {
  background-color: #d6ddd87d;
}

:deep(.invalid) {
  background-color: #D32F2F42;
}

:deep(.p-inputmask.p-inputtext.p-component.p-filled) {
  width: 80%;
}
</style>
